.navigation-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.link.navigation-link.navigation-link--active,
.link.navigation-link.navigation-link--active:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: #0058A3;
}

.link.navigation-link:hover {
    text-decoration: none;
    color: #767676;
    /* background-color: #007CC1; */
}

.link.navigation-link:hover .svg-icon.navigation-link__icon {
    fill: #767676;
}

.link.navigation-link.navigation-link--active .svg-icon.navigation-link__icon {
    fill: #ffffff;
}
