.ui-subsection {
    background-color: #ffffff;
    /* padding: 1rem; */
    border-radius: 0.5rem;
    border: 1px solid #dfdfdf;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
}

.ui-subsection > header {
    background-color: #f5f5f5;
    padding: 1rem;
    border-bottom: 1px solid #dfdfdf;
}

/* .ui-subsection > header > * {
    color: white;
} */

.ui-subsection > .ui-subsection__body {
    padding: 1rem;
}
