.app-layout {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 11rem 1fr;
  grid-template-rows: 5rem 1fr;
  grid-template-areas:
    "header header"
    "sidebar main";
}

.app-layout .app-header {
  grid-area: header;
}

.app-layout .app-layout__nav {
  grid-area: sidebar;
}

.app-layout .app-layout__main {
  grid-area: main;
  overflow-y: auto;
}
