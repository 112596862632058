.app-navigation {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.app-navigation__heading {
    padding: 1rem 1.5rem 0 1.5rem
}

.app-navigation__list {
    list-style: none;
    padding: 1rem !important;
    margin: 0;
}

.app-navigation hr {
    margin: 0;
    color: #DFDFDF;
}
