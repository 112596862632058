/* .nca-details {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto 10rem auto auto;
    grid-template-areas:
        "nca-header nca-header nca-header nca-header"
        "nca-data nca-data nca-data nca-data"
        "shipments shipments shipments shipments"
        "booking-history event-history changes documents"
        "event-history-oc edi changes documents";
} */

.nca-details__expandable-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nca-details__controls-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nca-details__controls-section > * {
    flex-grow: 0;
}

.ech-nca-details__events-grid {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    gap: 1rem;
}
