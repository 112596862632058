.app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
}

.app-header__section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.app-header__title {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 16rem;
}
